import React, { Component } from "react";
import ModalVideo from "react-modal-video";
import CountUp from "react-countup";
import VisibilitySensor from "react-visibility-sensor";
import fact2 from "../assets/images/HomePageImage21.png";
class SchoolVideo extends Component {
  constructor() {
    super();
    this.state = {
      isOpen: false,
      isOpen1: false,
      isOpen2: false,
      startCounter: false
    };
  }

  openModal = () => {
    this.setState({ isOpen: true });
  };
  openModal1 = () => {
    this.setState({ isOpen1: true });
  };
  openModal2 = () => {
    this.setState({ isOpen2: true });
  };

  onVisibilityChange = (isVisible) => {
    if (isVisible) {
      this.setState({ startCounter: true });
    }
  };

  render() {
    return (
      <section className="video-three">
        <div className="container">
          <div className="row">
          <div className="col-lg-6">
              <div className="video-three__boxs image1">
                <h2 className="video-three__title textwhite">
                GRADE 1-5 GRAND REOPENING 
                </h2>
                <div className="video-three__btn-box">
                  <ModalVideo
                    channel="youtube"
                    isOpen={this.state.isOpen1}
                    videoId="3woMEGozHUk"
                    onClose={() => this.setState({ isOpen1: false })}
                  />
                  <div
                    onClick={this.openModal1}
                    onKeyDown={this.openModal1}
                    role="button"
                    tabIndex={0}
                    className="video-three__popup"
                  >
                    <i className="fas fa-play"></i>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="video-three__boxs image2">
                <h2 className="video-three__title textwhite">
                Celeberation At Shanti Asiatic School
                </h2>
                <div className="video-three__btn-box">
                  <ModalVideo
                    channel="youtube"
                    isOpen={this.state.isOpen2}
                    videoId="DmLQi6ZkM-c"
                    onClose={() => this.setState({ isOpen2: false })}
                  />
                  <div
                    onClick={this.openModal2}
                    onKeyDown={this.openModal2}
                    role="button"
                    tabIndex={0}
                    className="video-three__popup"
                  >
                    <i className="fas fa-play"></i>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="video-three__boxs image">
                <h2 className="video-three__title textwhite">
                Glimpse of Shanti Asiatic School
                </h2>
                <div className="video-three__btn-box">
                  <ModalVideo
                    channel="youtube"
                    isOpen={this.state.isOpen}
                    videoId="hNo44tMVsg0"
                    onClose={() => this.setState({ isOpen: false })}
                  />
                  <div
                    onClick={this.openModal}
                    onKeyDown={this.openModal}
                    role="button"
                    tabIndex={0}
                    className="video-three__popup"
                  >
                    <i className="fas fa-play"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
          </div>
      </section>
    );
  }
}

export default SchoolVideo;
