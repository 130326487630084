import React from "react";
import Layout from "../components/Layout";
import Topbar from "../components/Topbar";
import NavOne from "../components/NavOne";
import SliderOne from "../components/SliderOne";
import Footer from "../components/Footer";
import AboutTwo from "../components/AboutTwo";
import AdmissionButton from "../components/AdmissionButton";
import SchoolCatOne from "../components/SchoolCatOne";
import SchoolCategory from "../components/SchoolCategory";
import SchoolToAction from "../components/SchoolToAction";
import SchoolToEffect from "../components/SchoolToEffect";
import SupInfrastructure from "../components/SupInfrastructure";
import SchoolVideo from "../components/SchoolVideo";
import SchoolVideo2 from "../components/SchoolVideo2";
import MediaCoverage from "../components/MediaCoverage";
import Inquiry from "../components/Inquiry";
import CreateArtical from "../components/CreateArtical";
import Testimonial from "../components/Testimonial";
import Demo from "../components/Demo";
import ImageSlider from "../components/ImageSlider";
import ScrollEvent from "../components/ScrollEvent";

const HomePage = () => (
  <Layout pageTitle="Shanti Asiatic School - Surat ">
    <Topbar />
    <NavOne />
   { /*event and welcome popup added in scroll event*/}
    <ScrollEvent/>
    <SliderOne />
    <SchoolCategory />
    <AboutTwo />
    <SchoolCatOne />
    <AdmissionButton />
    <SchoolToAction />
    <ImageSlider />
    <SchoolToEffect />
    <SupInfrastructure />
    <SchoolVideo2 />
    <div className="text-center" style={{marginTop:"10px"}}>
      <h2 className="block-title__title fontSize">Media Coverage</h2>
    </div>
    <MediaCoverage/>
    <Testimonial/>
    <Inquiry />
    <CreateArtical />
    <Footer />
  </Layout>
);

export default HomePage;
