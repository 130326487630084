import React, { useState, useEffect } from 'react'
import Swiper from 'react-id-swiper'
import 'swiper/swiper.min.css'
import Lightbox from 'react-image-lightbox'
import 'react-image-lightbox/style.css' // This only needs to be imported once in your app
import http from '../_api/http'
import { Link } from 'gatsby'
import './ImageSlider.css';
import { Autoplay, Pagination, Navigation } from "swiper";
// Import Swiper styles
import 'swiper/swiper.min.css'
import 'swiper/modules/pagination/pagination.min.css'

const ImageSlider = () => {

  const [loading, setLoading] = useState(true)
  const [images, setImage] = useState([]);
  const PUBLIC_URL = process.env.REACT_APP_APIURL
  const params = {
    slidesPerView: 5,
    loop:true,
    speed: 1000,
    spaceBetween: 100,
    autoplay: {
      delay: 500,
      disableOnInteraction: true
    }
  };

  useEffect(() => {
    // alert(process.env.REACT_APP_APIURL);
    http
      .get('api/imageupload/ApiImageUploadList?image_subcategory_id=17&section=Photo')
      .then(res => {
        // alert(res);
        // debugger
        if (res.data.status === 200) {
          setImage(res.data.image)
          setLoading(false)
        }
      })
  }, [])


  return (
    <>
      <section className='blog-two'>
        <div className='container'>
          <div className='block-title text-center'>
            <h1 className='block-title__title fontSize'>Images Gallary</h1>
          </div>

          <div className='blog-two__carousel'>
            {loading ? (
              <h4>View Image Loading...</h4>
            ) : (
              <>
                <div className="row" style={{ textAlign: "center", overflow: "hidden" }}>
                  <div className="col-md-6">
                    <Swiper
                      {...params}
                      navigation={true}
                      modules={[Autoplay, Pagination, Navigation]}
                      className="mySwiper"
                    >
                      {images.map((item, i) => (
                        <div
                          className='item'
                          key={i}
                        >
                          <Link to='/photo'> <img src={PUBLIC_URL + "uploadimage/" + item.image} height={200} width={200} /></Link>
                          {/* <div className='blog-two__meta'>
                            <a href='#none'>{item.image_title}</a>
                          </div> */}
                          <h4 className='blog-two__title' style={{ color: "black" }}>
                          </h4>
                          <br />
                        </div>
                      ))}
                    </Swiper>
                  </div>
                </div>
              </>
            )}
          </div>         
        </div>
      </section>
    </>
  )
}
export default ImageSlider
