import React, { useState } from 'react'
import Lightbox from 'react-image-lightbox'
import 'react-image-lightbox/style.css' // This only needs to be imported once in your app

const Demo = () => {
  const [photoIndex, setPhoto] = useState(0);
  const [isOpen, setisOpen] = useState(false);

  const images = [
    '//placekitten.com/1500/500',
    '//placekitten.com/4000/3000',
    '//placekitten.com/800/1200',
    '//placekitten.com/1500/1500'
  ]

  return (
    <section className=''>
        <button
          type='button'
          className='btn btn-primary'
          onClick={() => {
            setisOpen(true);
          }}
        >
          Open Lightbox {String(isOpen)}
        </button>
      <div>
        

        {isOpen && (
          <Lightbox
            mainSrc={images[photoIndex]}
            nextSrc={images[(photoIndex + 1) % images.length]}
            prevSrc={images[(photoIndex + images.length - 1) % images.length]}
            onCloseRequest={() => setisOpen(false)}
            onMovePrevRequest={() =>
              setPhoto((photoIndex + images.length - 1) % images.length)
            }
            onMoveNextRequest={() => setPhoto((photoIndex + 1) % images.length)}
          />
        )}
      </div>
    </section>
  )
}
export default Demo
