import React, { Component } from "react";
import cta1 from "../assets/images/OurEdge.png";
class SchoolToAction extends Component {
  render() {
    return (
      <section className="cta-three mt-5">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 clearfix">
              <img src={cta1} className="cta-three__image" alt="" />
            </div>
            <div className="col-lg-6">
              <div className="cta-three__contents">
                <div className="block-title text-left">
                  <h2 className="block-title__title title">
                  Our Edge: Centre of Excellence for a child’s comprehensive development
                  </h2>
                </div>
                <p className="cta-three__text">
                Right from the inception of SAS, the school has maintained a very high ground, ensuring a
                cultivating environment is provided for the students to grow and evolve. <br/> The school also houses
                the finest teachers for respective subjects thereby no compromise is made when it comes to
                academics. What makes Shanti Asiatic School, Surat stand ahead in the queue?
                </p>
                <div className="cta-three__single-wrap">
                  <div className="cta-three__single">
                    <i className="kipso-icon-strategy"></i>
                    <p className="cta-three__single-text mb-3">    
                    Excellent academic records
                    </p>
                  </div>
                  <div className="cta-three__single">
                    <i className="kipso-icon-strategy"></i>
                    <p className="cta-three__single-text">    
                    Interactive Learning
                    </p>
                  </div>
                  <div className="cta-three__single">
                    <i className="kipso-icon-training"></i>
                    <p className="cta-three__single-text">Subject Experts</p> 
                  </div>

                  <div className="cta-three__single">
                    <i className="kipso-icon-training"></i>
                    <p className="cta-three__single-text">Focus on co-curricular</p> 
                  </div>

                  <div className="cta-three__single">
                    <i className="kipso-icon-human-resources"></i>
                    <p className="cta-three__single-text">Modern Infrastructure</p>
                  </div>
                  <div className="cta-three__single">
                    <i className="kipso-icon-strategy"></i>
                    <p className="cta-three__single-text">    
                    Advanced Labs
                    </p>
                  </div>
                </div>

                
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default SchoolToAction;
