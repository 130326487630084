import React from "react";
import circleStripe from "../assets/images/circle-stripe.png";
import category1 from "../assets/images/bgstep.png";
import icon1 from "../assets/images/Step - 01.png";
import icon2 from "../assets/images/Step-02.png";
import icon3 from "../assets/images/Ste-03.png";
import icon4 from "../assets/images/Step-04.png";
const SchoolCatOne = () => {
  return (
    <div>
      <section className="course-one__top-title home-one">
        <div className="container">
          <div className="block-title mb-0">
            <h2 className="block-title__title fontSize">
              Details Of Our Admission Procedures
            </h2>
          </div>
        </div>
        <div className="course-one__top-title__curve"></div>
      </section>
      <section className="contact-info-one mb-5">
        <div className="container">
          <div className="row">
            <div className="col-lg-3">
              <div className="contact-info-one__single  bg-light">
                <h2 className="contact-info-one__title">Step: 1</h2>
                <p className="contact-info-one__text">
                  1. Fill and submit the admission form.<br />
                  2. We will contact you on given phone number/ e-mail to visit the school campus for counselling, interaction & verification of original documents.
                </p>
                <hr />
                <div className="contact-info-one__icon resize">
                  <img src={icon1} ></img>
                </div>

              </div>
            </div>
            <div className="col-lg-3">
              <div className="contact-info-one__single  bg-light">
                <h2 className="contact-info-one__title">Step: 2</h2>
                <p className="contact-info-one__text">
                  1. On the schedule day the application form (available from school) has to be submitted
                  along with the required documents at admission office.<br />
                  2. Entrance exam will be conducted.
                </p>
                <hr />
                <div className="contact-info-one__icon resize">
                  <img src={icon2} ></img>
                </div>

              </div>
            </div>
            <div className="col-lg-3">
              <div className="contact-info-one__single  bg-light">
                <h2 className="contact-info-one__title">Step: 3</h2>
                <p className="contact-info-one__text">
                  1. Post satisfactory evaluation, further process will be initiated.<br />
                  2. Information about the school, including academics, infrastructure and facilities will be explained along with the fee details.
                </p>
                <hr />
                <div className="contact-info-one__icon resize">
                  <img src={icon3} ></img>
                </div>

              </div>
            </div>
            <div className="col-lg-3">
              <div className="contact-info-one__single  bg-light">
                <h2 className="contact-info-one__title">Step: 4</h2>
                <p className="contact-info-one__text">
                  1. On successful completion of above process, the parents are required to complete the
                  enrolment formalities by remitting the “Amount payable at the time of admission”
                </p>
                <hr /><hr />
                <div className="contact-info-one__icon resize">
                  <img src={icon4} ></img>
                </div>

              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default SchoolCatOne;
