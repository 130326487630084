import React from "react";
import ctabg1 from "../assets/images/patter-1-1.jpg";
const AdmissionButton = () => {
  return (
    <section
      className="cta-one cta-one__home-one"
      style={{ backgroundImage: `url(${ctabg1})` }}
    >
      <div className="container">
        {/* <h2 className="cta-one__titles">
        We are a school with a difference. Our approach to education, learning and development as a
        whole has set a benchmark. Our infrastructure coupled with our approach and values is the
        ingredient for a child’s all-round development and growth.
        Enroll today for child’s admission and ensure their brighter tomorrow.
        </h2> */}
        <div className="cta-one__btn-block">
          <a href="/admission" className="thm-btn cta-one__btn">
          Apply for Admission Now
          </a>
        </div>
      </div>
    </section>
  );
};

export default AdmissionButton;
